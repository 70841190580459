<template>
  <div class="newsList_center" @click="handleClick">
    <div class="word">
      <div class="title">
        {{ item.title }}
      </div>
      <div class="newsInfo">
        <div class="channel">
          {{ item.channel }}
        </div>
        <div class="round" />
        <div class="time">
          {{ $global.formatDate($global.dateStringToTimestamp(item.published),'MM-dd-yyyy hh:mm') }}
        </div>
      </div>
    </div>
    <div class="picurl">
      <img v-lazy="item.headImg?.[0]" alt="">
    </div>
  </div>
</template>

<script>
import '@/css/module/lead/theme_0.scss';

export default {
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
  methods: {
    handleClick () {
      this.$emit('customClick');
    }
  }
};
</script>
